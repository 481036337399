<template>
  <div class="container_con" style="background: none;">
    <el-form ref="form" :rules="rules" :model="form" label-width="160px">
      <el-card>
        <div class="con_title"><span></span> 基础信息</div>
        <div class="basis_box">
          <div style="width: 100%">
            <el-form-item label="日期：" prop="record_date">
              <el-date-picker
                v-model="form.record_date"
                type="date" value-format="YYYY-MM-DD"
                style="width:300px"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="部门：">
              <el-select
                v-model="form.region"
                placeholder="请选择"
                style="width:300px"
              >
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item> -->
            
            
            <el-form-item label="适应温度范围：" prop="temp_upper">
              <el-input
                v-model="form.temp_lower"
                placeholder="请输入"
                style="width:120px"
              />
              ℃ 至
              <el-input
                v-model="form.temp_upper"
                placeholder="请输入"
                style="width:120px"
              />
              ℃
            </el-form-item>
            <el-form-item label="适应湿度范围：" prop="humidity_upper">
              <el-input
                v-model="form.humidity_lower"
                placeholder="请输入"
                style="width:120px"
              />
              % 至
              <el-input
                v-model="form.humidity_upper"
                placeholder="请输入"
                style="width:120px"
              />
              %
            </el-form-item>
          </div>
          <div>
            
          </div>
        </div>
      </el-card>
      <el-card>
        <div class="con_title"><span></span> 上午温湿度</div>
        <el-form-item label="上午温度：" prop="temp_am">
          <el-input
            v-model="form.temp_am"
            placeholder="请输入"
            style="width:120px"
          />
          ℃
        </el-form-item>
        <el-form-item label="上午湿度：" prop="humidity_am">
          <el-input
            v-model="form.humidity_am"
            placeholder="请输入"
            style="width:120px"
          />
          %
        </el-form-item>
        <!-- <el-form-item label="上午温度超标时间：">
          <el-date-picker
            v-model="value1"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item label="上午超标采取措施：" prop="measure_am">
          <el-input
            type="textarea"
            v-model="form.measure_am"
            placeholder="请输入"
            style="width:420px"
          />
        </el-form-item>
        

        <div class="line"></div>
        <el-form-item label="上午采取措施后温度：" prop="measure_temp_am">
          <el-input
            v-model="form.measure_temp_am"
            placeholder="请输入"
            style="width:120px"
          />
          ℃
        </el-form-item>
        <!-- <el-form-item label="上午湿度超标时间：">
          <el-date-picker
            v-model="value1"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->
        <!-- <el-form-item label="上午湿度超标采取措施：">
          <el-input
            type="textarea"
            v-model="form.name"
            placeholder="请输入"
            style="width:420px"
          />
        </el-form-item> -->
        <el-form-item label="上午采取措施后湿度：" prop="measure_humidity_am">
          <el-input
            v-model="form.measure_humidity_am"
            placeholder="请输入"
            style="width:120px"
          />
          %
        </el-form-item>
      </el-card>

      <el-card>
        <div class="con_title"><span></span> 下午温湿度</div>
        <el-form-item label="下午温度：" prop="temp_pm">
          <el-input
            v-model="form.temp_pm"
            placeholder="请输入"
            style="width:120px"
          />
          ℃
        </el-form-item>
        <el-form-item label="下午湿度：" prop="humidity_pm">
          <el-input
            v-model="form.humidity_pm"
            placeholder="请输入"
            style="width:120px"
          />
          %
        </el-form-item>
        <!-- <el-form-item label="下午温度超标时间：">
          <el-date-picker
            v-model="value1"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item label="下午超标采取措施：" prop="measure_pm">
          <el-input
            type="textarea"
            v-model="form.measure_pm"
            placeholder="请输入"
            style="width:420px"
          />
        </el-form-item>
        

        <div class="line"></div>
        <el-form-item label="下午采取措施后温度：" prop="measure_temp_pm">
          <el-input
            v-model="form.measure_temp_pm"
            placeholder="请输入"
            style="width:120px"
          />
          ℃
        </el-form-item>
        
        <!-- <el-form-item label="下午湿度超标时间：">
          <el-date-picker
            v-model="value1"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->
        <!-- <el-form-item label="下午湿度超标采取措施：">
          <el-input
            type="textarea"
            v-model="form.name"
            placeholder="请输入"
            style="width:420px"
          />
        </el-form-item> -->
        <el-form-item label="上午采取措施后湿度：" prop="measure_humidity_pm">
          <el-input
            v-model="form.measure_humidity_pm"
            placeholder="请输入"
            style="width:120px"
          />
          %
        </el-form-item>
      </el-card>
    </el-form>
  </div>
  <div class="bottom_btn">
      <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
      <el-button @click="$router.go(-1)">取消</el-button>
  </div>
</template>

<script>
export default {
  name: "addTemperature",
  data() {
    return {
      id: '',
      is_ajax: false,
      form: {
          
      },
      rules: {
        record_date: [
          { required: true, message: "记录日期不能为空",trigger: "blur"}
        ],
        temp_upper: [
          { required: true, message: "适宜温度范围上限不能为空",trigger: "blur"}
        ],
        temp_lower: [
          { required: true, message: "适宜温度范围下限不能为空",trigger: "blur"}
        ],
        humidity_upper: [
          { required: true, message: "适宜湿度范围上限不能为空",trigger: "blur"}
        ],
        humidity_lower: [
          { required: true, message: "适宜湿度范围下限不能为空",trigger: "blur"}
        ],
        temp_am: [
          { required: true, message: "上午温度不能为空",trigger: "blur"}
        ],
        temp_pm: [
          { required: true, message: "下午温度不能为空",trigger: "blur"}
        ],
        humidity_am: [
          { required: true, message: "上午湿度不能为空",trigger: "blur"}
        ],
        humidity_pm: [
          { required: true, message: "下午湿度不能为空",trigger: "blur"}
        ]
      }
    };
  },
  created() {
      this.id=this.$route.query.id || '';
      if(this.id){
          this.get_data()
      }else{
          this.form.record_date=this.today_val();
          this.get_cs()
      }
  },
  methods: {
      // 表单重置
      reset() {
        this.form = {
          title:'',
          remark:'',
          sort:0,
          record_date:this.today_val()
        };
        if(this.$refs['form']){
          this.$refs['form'].clearValidate();
        }
      },
      /** 提交按钮 */
      submitForm: function(form) {
        this.$refs[form].validate((valid)  => {
          if (valid) {
            this.is_ajax=true;
            if(form=='form'){
              this.$httpPost("/backend/WarehouseTempHumidity/save", this.form).then((res) => {
                  if (res.status == 200) {
                      if(this.form.id){
                          this.$message.success('更新成功');
                          this.$router.go(-1)
                      }else{
                          this.$message.success('新增成功');
                          // this.reset();
                          this.$router.go(-1)
                      }
                  } else {
                      this.$message.error(res.message);
                  }
                  this.is_ajax=false;
              }).catch((err) => {
                  console.log(err);
                  this.is_ajax=false;
              });
            }
          }
        });
      },
      get_data(){
          this.$httpGet("/backend/WarehouseTempHumidity/read", {id:this.id}).then((res) => {
              if (res.status == 200) {
                  this.form=res.data
              } else {
                  this.$message.error(res.message);
              }
          }).catch((err) => {
              console.log(err);
          });
      },
      get_cs(){
          this.$httpGet("/backend/WarehouseTempHumidity/measures", {}).then((res) => {
              if (res.status == 200) {
                  this.form.measure_am=res.data;
                  this.form.measure_pm=res.data;
              } else {
                  this.$message.error(res.message);
              }
          }).catch((err) => {
              console.log(err);
          });
          
      }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 15px;
}
.basis_box {
  display: flex;
}
.el-card {
  margin-bottom: 15px;
}
.line {
  height: 1px;
  background: #dcdee0;
  margin-bottom: 20px;
}
</style>
